import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyACT0v0qg-08JnYaEoFxiS0laTzZ9rnN0c",
    authDomain: "ck-goatfarm.firebaseapp.com",
    projectId: "ck-goatfarm",
    storageBucket: "ck-goatfarm.appspot.com",
    messagingSenderId: "844509597602",
    appId: "1:844509597602:web:0468853a5076793d8eecfb",
    measurementId: "G-SG8H4EZP94"
  };
  

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const firestore = getFirestore(app);
  const provider = new GoogleAuthProvider();

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userRef = doc(firestore, "users", user.uid);
      const docSnap = await getDoc(userRef);
  
      if (docSnap.exists() && docSnap.data().admin === true) {
          const configurePersistence = async () => {
              try {
                  await setPersistence(auth, browserLocalPersistence); // Keeps the user logged in even after closing the browser
              } catch (error) {
                  console.error('Error setting persistence:', error);
              }
          };
          await configurePersistence();
        return true;  // Admin
      } else {
        return false; // Not an admin
      }
    } catch (error) {
      console.error("Error during sign-in:", error);
      throw error;
    }
  };
  
  // Initialize Firestore
    const db = getFirestore(app);
export { db };
export { signInWithGoogle };
export {auth}
